/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { cast, flow, types, getRoot, Instance } from 'mobx-state-tree';
import { TRole, TStore } from 'types';
import {
  TAssignedCoursesCountResponse,
  TAssignedTracksCountResponse,
  TMyTeamCourseResponse,
  TMyTeamTrackResponse,
  TRolesAllResponse,
} from 'types/myTeamApiTypes';
import { ActivityRange, CourseUserStatus, GroupUserStatus } from '_constants';
import MActivityInfo from './MActivityInfo';
import MAttendees from './MAttendees';
import MCourse from './MCourse';
import MCourseInfo from './MCourseInfo';
import MManagers from './MManagers';
import MRole from './MRole';
import MTrack from './MTrack';
import MTrackInfo from './MTrackInfo';
import MCourseAttendees from './MCourseAttendees';
import MTrackAttendees from './MTrackAttendees';

const MGroup = types
  .model({
    groupId: types.identifierNumber,
    courseId: types.maybeNull(types.number),
    managersStore: types.optional(MManagers, {}),
    allAttendeesStore: types.optional(MAttendees, {}),
    withoutAssignmentsAttendeesStore: types.optional(MAttendees, {}),
    assignedCoursesCount: types.maybeNull(types.number),
    assignedTracksCount: types.maybeNull(types.number),
    expiredCoursesCount: types.maybeNull(types.number),
    expiredTracksCount: types.maybeNull(types.number),
    activity: types.optional(MActivityInfo, {}),
    coursesInfo: types.maybeNull(types.array(MCourseInfo)),
    tracksInfo: types.maybeNull(types.array(MTrackInfo)),
    courses: types.map(MCourse),
    tracks: types.map(MTrack),
    roles: types.array(MRole),
    expiredCourseAttendees: types.map(
      types.model({
        id: types.identifier,
        data: MCourseAttendees,
      }),
    ),
    expiredTrackAttendees: types.map(
      types.model({
        id: types.identifier,
        data: MTrackAttendees,
      }),
    ),
  })
  .views((self) => ({
    get myTeamUIStore() {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
    get allActivityMyTeamDomainStore(): Instance<typeof MActivityInfo> {
      return getRoot<TStore>(self).DomainStore.myTeam.allActivity;
    },
    get coursesWithExpiredAttendees() {
      return self.coursesInfo?.filter((course) => course.hasExpiredAttendees);
    },
    get coursesExpiredAttendeesSum() {
      return self.coursesInfo?.reduce(
        (sum, course) => sum + (course.hasExpiredAttendees ? course.expiredCount || 0 : 0),
        0,
      );
    },
    get tracksWithExpiredAttendees() {
      return self.tracksInfo?.filter((track) => track.hasExpiredAttendees);
    },
    get tracksExpiredAttendeesSum() {
      return self.tracksInfo?.reduce(
        (sum, track) => sum + (track.hasExpiredAttendees ? track.expiredCount || 0 : 0),
        0,
      );
    },
  }))
  .actions((self) => ({
    createCourse: (id: number) => {
      self.courses.put(MCourse.create({ courseId: id }));
    },
    createTrack: (id: string) => {
      self.tracks.put(MTrack.create({ id }));
    },
    deleteTrack: (id: string) => {
      self.tracks.delete(String(id));
    },
    fetchAssignCourses: flow(function* fetchAssignCourses(groupId: number) {
      const { data, hasError }: TMyTeamCourseResponse = yield myTeamApi.courseApi.getAssignCourses(groupId);
      if (data) {
        self.coursesInfo = cast(data);
      }
      return !hasError;
    }),
    fetchAssignTracks: flow(function* fetchAssignTracks(groupId: number) {
      const { data, hasError }: TMyTeamTrackResponse = yield myTeamApi.tracksApi.getAssignTracks(groupId);
      if (data) {
        self.tracksInfo = cast(data);
      }
      return !hasError;
    }),
    getAssignedCoursesCount: flow(function* getAssignedCoursesCount(groupId: number) {
      const { data, hasError }: TAssignedCoursesCountResponse = yield myTeamApi.courseApi.getAssignCoursesCount(
        groupId,
      );
      if (data) {
        self.assignedCoursesCount = data.assignedCoursesCount;
        self.expiredCoursesCount = data.expiredCoursesAttendeesCount || null;
      }
      return !hasError;
    }),
    getAssignedTracksCount: flow(function* getAssignedTracksCount(groupId: number) {
      const { data, hasError }: TAssignedTracksCountResponse = yield myTeamApi.tracksApi.getAssignTracksCount(groupId);
      if (data) {
        self.assignedTracksCount = data.assignedTracksCount;
        self.expiredTracksCount = data.expiredTracksAttendeesCount || null;
      }
      return !hasError;
    }),
    fetchRoles: flow(function* fetchRoles() {
      const { data, hasError }: TRolesAllResponse = yield myTeamApi.groupApi.getRoles();
      if (data) {
        self.roles = cast(data);
      }
      return !hasError;
    }),
  }))
  .actions((self) => ({
    setCourseId: (id: number) => {
      if (!self.courses.get(String(id))) {
        self.createCourse(id);
      }
      self.courseId = id;
    },
    setTrackId: (id: string) => {
      if (!self.tracks.get(id)) {
        self.createTrack(id);
      }
    },
    fetchGroupRequiredData: flow(function* getGroupRequiredData(id: number) {
      const getManagers = async () => {
        const success = await self.fetchRoles();
        if (!success) return null;
        return self.managersStore.fetchManagers(
          id,
          self.roles.map((r: TRole) => r.id),
        );
      };
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([
        getManagers(),
        self.allActivityMyTeamDomainStore.fetchActivity(),
        self.activity.fetchActivity(id),
        self.getAssignedCoursesCount(id),
        self.getAssignedTracksCount(id),
        self.allAttendeesStore.fetchAttendees(id, GroupUserStatus.ALL),
        self.withoutAssignmentsAttendeesStore.fetchAttendees(id, GroupUserStatus.WITHOUT_ASSIGNMENTS),
      ]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchGroupActivityRequiredData: flow(function* getGroupActivityRequiredData(groupId: number) {
      self.activity.createAttendeesGroup(ActivityRange.MONTH);
      self.activity.createAttendeesGroup(ActivityRange.WEEK);
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([
        self.activity.attendees.get(ActivityRange.MONTH)?.fetchActiveAttendees(groupId),
        self.activity.attendees.get(ActivityRange.MONTH)?.fetchInactiveAttendees(groupId),
        self.activity.attendees.get(ActivityRange.MONTH)?.fetchAttendeesCount(groupId),
        self.activity.attendees.get(ActivityRange.WEEK)?.fetchActiveAttendees(groupId),
        self.activity.attendees.get(ActivityRange.WEEK)?.fetchInactiveAttendees(groupId),
        self.activity.attendees.get(ActivityRange.WEEK)?.fetchAttendeesCount(groupId),
      ]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchCoursesRequiredData: flow(function* fetchCoursesRequiredData(groupId: number) {
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([self.fetchAssignCourses(groupId)]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchExpiredAttendeesForCourses: flow(function* fetchExpiredAttendeesForCourses(groupId: number) {
      self.myTeamUIStore.setIsFetching(true);
      const getAttendees = async (courseId: number) => {
        self.expiredCourseAttendees.put({ id: String(courseId), data: MCourseAttendees.create() });
        return self.expiredCourseAttendees
          .get(String(courseId))
          ?.data.fetchAttendees(courseId, groupId, CourseUserStatus.EXPIRED);
      };
      let responses: boolean[] = [];
      if (self.coursesWithExpiredAttendees) {
        responses = yield Promise.all(self.coursesWithExpiredAttendees.map((course) => getAttendees(course.id)));
      }
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchExpiredAttendeesForTracks: flow(function* fetchExpiredAttendeesForTracks(groupId: number) {
      self.myTeamUIStore.setIsFetching(true);
      const getAttendees = async (trackId: string) => {
        self.expiredTrackAttendees.put({ id: trackId, data: MTrackAttendees.create() });
        return self.expiredTrackAttendees.get(trackId)?.data.fetchAttendees(trackId, groupId, CourseUserStatus.EXPIRED);
      };
      let responses: boolean[] = [];
      if (self.tracksWithExpiredAttendees) {
        responses = yield Promise.all(self.tracksWithExpiredAttendees.map((track) => getAttendees(track.id)));
      }
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchTracksRequiredData: flow(function* fetchCoursesRequiredData(groupId: number) {
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([self.fetchAssignTracks(groupId)]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
  }));

export default MGroup;
