/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { myTeamApi } from 'api';
import { TTrackAttendeeResponse } from 'types/myTeamApiTypes';
import { CourseUserStatus } from '_constants';
import MCourseAttendee from './MCourseAttendee';
import MPaginationContext from './MPaginationContext';

const MTrackAttendees = types
  .model({
    attendees: types.maybeNull(types.array(MCourseAttendee)),
    context: types.optional(MPaginationContext, {}),
    groupId: types.maybeNull(types.number),
    trackId: types.maybe(types.string),
    userStatus: types.optional(
      types.enumeration<CourseUserStatus>('Rage', Object.values(CourseUserStatus)),
      CourseUserStatus.ASSIGNED,
    ),
    isFetching: false,
  })
  .actions((self) => ({
    fetchAttendees: flow(function* getAttendees(trackId: string, groupId: number, userStatus: CourseUserStatus) {
      self.isFetching = true;
      const { data, hasError }: TTrackAttendeeResponse = yield myTeamApi.tracksApi.getTrackAttendees(
        trackId,
        groupId,
        userStatus,
      );
      if (!!data && !hasError) {
        self.attendees = cast(data.items);
        self.context = data.context;
        self.userStatus = userStatus;
        self.groupId = groupId;
        self.trackId = trackId;
      }
      self.isFetching = false;
      return data;
    }),
    fetchMoreAttendees: flow(function* fetchMoreAttendees() {
      if (!self.trackId || !self.groupId || !self.userStatus) return null;
      self.isFetching = true;
      const { data, hasError }: TTrackAttendeeResponse = yield myTeamApi.tracksApi.getTrackAttendees(
        self.trackId,
        self.groupId,
        self.userStatus,
        self.context.page + 1,
      );
      if (!!data && !hasError) {
        self.attendees = cast(self.attendees?.concat(data.items));
        self.context = data.context;
      }
      self.isFetching = false;
      return data;
    }),
  }));

export default MTrackAttendees;
