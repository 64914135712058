import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';

const MCourseAttendee = types.model({
  id: types.number,
  eventId: types.number,
  firstName: types.string,
  lastName: types.string,
  thumbnail: types.maybeNull(types.string),
  finishedStagesCount: types.maybeNull(types.number),
  stagesCount: types.maybeNull(types.number),
  stepsCount: types.maybeNull(types.number),
  finishedStepsCount: types.maybeNull(types.number),
  points: types.number,
  deadlineDate: types.maybeNull(types.string),
  completedDate: types.maybeNull(types.string),
  currentCourseStageName: types.maybeNull(types.string),
  currentTrackStepName: types.maybeNull(types.string),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
});

export default MCourseAttendee;
