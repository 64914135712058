import React, { useState } from 'react';
import { StepsProps } from 'antd';

import { ReactComponent as CheckmarkEllipse } from 'assets/icons/checkmarkEllipse.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

import { TStep } from 'types';
import { LearningTrackStepsStatuses } from 'models/LearningTracks/constants';
import { useAppNavigator, useVisibility } from 'utils';
import { RejectTypes, TRejectTypes } from 'views/LearningTracks/constants';
import { TrackRejectModal } from 'components';
import store from 'store';
import CircleProgress from '../CircleProgress';
import {
  AvatarWrapper,
  StyledAvatar,
  StyledSteps,
  StyledTooltip,
  PercentText,
  StyledBadge,
  DescriptionOverlay,
  StyledTooltipWrapper,
} from './GamificationSteps.styles';

interface OwnProps {
  steps?: TStep[];
  viewInAdminMode?: boolean;
}

type Props = StepsProps & OwnProps;

const GamificationSteps: React.FC<Props> = ({ steps = [], viewInAdminMode, ...props }) => {
  const rejectModalVisibility = useVisibility();
  const [rejectModalType, setRejectModalType] = useState<TRejectTypes | null>(null);
  const {
    params: { trackId },
    goToLearningTrackCourse,
  } = useAppNavigator();
  const {
    learningTrack: { isShowStepClosedIcon, fetchIsLearningTrackStepOpened },
  } = store.DomainStore;

  const onTooltipClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const renderSteps = () =>
    steps.map(({ id, name, coverThumbnailUrl, status, content, progress }) => {
      const percent = Math.round(progress * 100);
      const finished = status === LearningTrackStepsStatuses.COMPLETED;
      const waiting =
        status === LearningTrackStepsStatuses.NOT_AVAILABLE || status === LearningTrackStepsStatuses.CLOSED;

      const onStepClick = async () => {
        if (viewInAdminMode && content.id) {
          const { isStepOpened } = await fetchIsLearningTrackStepOpened(trackId, id);
          if (isStepOpened) {
            goToLearningTrackCourse(content.id, id);
          }
        } else if (
          status === LearningTrackStepsStatuses.NOT_AVAILABLE ||
          (status === LearningTrackStepsStatuses.CLOSED && !isShowStepClosedIcon(id))
        ) {
          setRejectModalType(RejectTypes.YET_UNAVAILABLE);
          rejectModalVisibility.show();
        } else if (status === LearningTrackStepsStatuses.CLOSED && isShowStepClosedIcon(id)) {
          setRejectModalType(RejectTypes.CLOSED);
          rejectModalVisibility.show();
        } else if (content.id) {
          const { isStepOpened } = await fetchIsLearningTrackStepOpened(trackId, id);
          if (isStepOpened) {
            goToLearningTrackCourse(content.id, id);
          }
        }
      };

      const Description = (
        <DescriptionOverlay onClick={onStepClick} waiting={waiting}>
          <StyledBadge count={finished ? <CheckmarkEllipse /> : undefined}>
            <CircleProgress
              percentValue={percent}
              format={() => (
                <AvatarWrapper waiting={waiting}>
                  <StyledAvatar
                    size={96}
                    {...(status === LearningTrackStepsStatuses.CLOSED && isShowStepClosedIcon(id)
                      ? { icon: <LockIcon />, className: 'step-closed' }
                      : { src: coverThumbnailUrl })}
                  />
                </AvatarWrapper>
              )}
              isVisible={
                status === LearningTrackStepsStatuses.IN_PROGRESS ||
                status === LearningTrackStepsStatuses.NOT_STARTED ||
                status === LearningTrackStepsStatuses.COMPLETED
              }
            />
          </StyledBadge>
          <StyledTooltipWrapper onClick={onTooltipClick}>
            <StyledTooltip title={name} placement="bottom" getPopupContainer={(trigger) => trigger}>
              {name}
            </StyledTooltip>
          </StyledTooltipWrapper>
          <PercentText finished={finished}>{`${percent}%`}</PercentText>
        </DescriptionOverlay>
      );
      const getStatus = () => {
        if (status === LearningTrackStepsStatuses.NOT_AVAILABLE) {
          return 'wait';
        }
        if (status === LearningTrackStepsStatuses.IN_PROGRESS || status === LearningTrackStepsStatuses.NOT_STARTED) {
          return 'process';
        }
        if (status === LearningTrackStepsStatuses.COMPLETED) {
          return 'finish';
        }
        return undefined;
      };

      return <StyledSteps.Step key={id} description={Description} status={getStatus()} />;
    });

  return (
    <>
      <StyledSteps {...props} direction="vertical">
        {renderSteps()}
      </StyledSteps>
      <TrackRejectModal
        rejectType={rejectModalType}
        visible={rejectModalVisibility.visible}
        onClose={rejectModalVisibility.hide}
      />
    </>
  );
};

export default GamificationSteps;
