import { isTalentRocksApp } from 'env';
import optionPrefix from 'utils/optionPrefix';

export enum Routes {
  ALIAS = '/:alias',

  AUTH = '/auth',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  SIGN_UP_STEP = '/step/:stepId',
  RESET_PASSWORD = '/reset-password',
  CALLBACK = '/callback',

  MY_COURSES = '/my-courses',
  COURSES_STATUS = '/:coursesStatus',
  COURSES_ALL = '/all',
  COURSES_IN_PROGRESS = '/in-progress',
  COURSES_AVAILABLE = '/available',
  COURSES_COMPLETED = '/completed',

  COURSE = '/course/:courseId',
  STAGE = '/stage/:stageId',
  SCORM = '/scorm/:scormId',
  CATALOG = ':catalog(/catalog/\\d+)+',
  POLL = '/poll/:pollId',
  POLL_SCREEN = '/screen/:screenId',
  POLL_FINISH = '/finish',
  POLL_RESULT = '/result',

  FINISH_POLL = '/finish-poll',

  MY_POLLS = '/my-polls',
  MY_POLLS_ACTIVE = '/active',
  MY_POLLS_FINISHED = '/finished',

  GAMIFICATION = '/gamification',
  GROUP_GAMIFICATION = '/group-gamification',
  GAMIFICATION_RATINGS = '/ratings',
  GAMIFICATION_RATINGS_GROUP = '/:groupId?',
  GAMIFICATION_RATINGS_DETAILS = '/details',
  GAMIFICATION_RULES = '/rules',
  GAMIFICATION_PRIZES = '/prizes',
  GAMIFICATION_PRIZE = '/:prizeId',

  MY_TEAM = '/my-team',
  GROUP = '/groups/:groupId',
  ACTIVITY = '/activity',
  COURSES = '/courses',
  TRACKS = '/tracks',

  MY_BOOKINGS = '/my-bookings',
  BOOKING = '/booking/:bookingId',
  BOOKINGS_CURRENT = '/current',
  BOOKINGS_AVAILABLE = '/available',
  BOOKING_REGISTRATIONS = '/registrations',

  FRAME = '/frame/:frameSlug',

  MY_TASKS = '/my-tasks',
  TASK = '/task/:taskId',
  TASK_FINISHED = '/finished',
  TASK_ANSWERS = '/answers',
  TASK_REVIEW = '/review',
  TASKS_ACTIVE = '/active',
  TASKS_COMPLETED = '/completed',
  SCHEDULE = '/schedule',

  MY_LEARNING_TRACKS = '/my-learning-tracks',
  MY_LEARNING_TRACKS_ALL = '/all',
  MY_LEARNING_TRACKS_IN_PROGRESS = '/in-progress',
  MY_LEARNING_TRACKS_AVAILABLE = '/available',
  MY_LEARNING_TRACKS_COMPLETED = '/completed',
  MY_LEARNING_TRACK = '/track/:trackId',
  MY_LEARNING_TRACKS_STATUS = '/:learningTracksStatus',
  MY_LEARNING_TRACK_STEP = '/step/:trackStepId',

  DASHBOARD = '/dashboard',
}

const {
  AUTH,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_STEP,
  RESET_PASSWORD,
  CALLBACK,
  ALIAS,
  MY_COURSES,
  COURSE,
  COURSES_ALL,
  CATALOG,
  COURSES_AVAILABLE,
  COURSES_COMPLETED,
  COURSES_IN_PROGRESS,
  STAGE,
  POLL,
  SCORM,
  POLL_FINISH,
  POLL_RESULT,
  POLL_SCREEN,
  MY_POLLS,
  MY_POLLS_ACTIVE,
  MY_POLLS_FINISHED,
  COURSES_STATUS,
  GAMIFICATION,
  GROUP_GAMIFICATION,
  GAMIFICATION_RATINGS,
  GAMIFICATION_RATINGS_GROUP,
  GAMIFICATION_RULES,
  GAMIFICATION_PRIZES,
  GAMIFICATION_PRIZE,
  GAMIFICATION_RATINGS_DETAILS,
  MY_TEAM,
  GROUP,
  ACTIVITY,
  COURSES,
  TRACKS,
  MY_BOOKINGS,
  BOOKING,
  BOOKINGS_CURRENT,
  BOOKINGS_AVAILABLE,
  BOOKING_REGISTRATIONS,
  FRAME,
  MY_TASKS,
  TASK,
  TASK_ANSWERS,
  TASK_FINISHED,
  TASK_REVIEW,
  TASKS_ACTIVE,
  TASKS_COMPLETED,
  SCHEDULE,
  MY_LEARNING_TRACKS,
  MY_LEARNING_TRACKS_ALL,
  MY_LEARNING_TRACKS_IN_PROGRESS,
  MY_LEARNING_TRACKS_AVAILABLE,
  MY_LEARNING_TRACKS_COMPLETED,
  MY_LEARNING_TRACK,
  MY_LEARNING_TRACKS_STATUS,
  MY_LEARNING_TRACK_STEP,
  DASHBOARD,
} = Routes;

export const RouteTemplates = {
  alias: `${ALIAS}`,
  coursesStatus: `${ALIAS}${MY_COURSES}${COURSES_STATUS}`,
  myCourses: `${ALIAS}${MY_COURSES}`,
  allCourses: `${ALIAS}${MY_COURSES}${COURSES_ALL}`,
  inProgressCourses: `${ALIAS}${MY_COURSES}${COURSES_IN_PROGRESS}`,
  completedCourses: `${ALIAS}${MY_COURSES}${COURSES_COMPLETED}`,
  availableCourses: `${ALIAS}${MY_COURSES}${COURSES_AVAILABLE}`,

  myTeam: `${ALIAS}${MY_TEAM}`,
  myTeamActivity: `${ALIAS}${MY_TEAM}${ACTIVITY}`,
  myTeamGroup: `${ALIAS}${MY_TEAM}${GROUP}`,
  myTeamGroupActivity: `${ALIAS}${MY_TEAM}${GROUP}${ACTIVITY}`,
  myTeamGroupCourses: `${ALIAS}${MY_TEAM}${GROUP}${COURSES}`,
  myTeamGroupCourse: `${ALIAS}${MY_TEAM}${GROUP}${COURSE}`,
  myTeamGroupTracks: `${ALIAS}${MY_TEAM}${GROUP}${TRACKS}`,
  myTeamGroupTrack: `${ALIAS}${MY_TEAM}${GROUP}${MY_LEARNING_TRACK}`,
  myTeamGroupTrackCourse: `${ALIAS}${MY_TEAM}${GROUP}${MY_LEARNING_TRACK}${COURSE}`,

  auth: `${ALIAS}${AUTH}`,
  authSignIn: `${ALIAS}${AUTH}${SIGN_IN}`,
  authSignUp: `${ALIAS}${AUTH}${SIGN_UP}${SIGN_UP_STEP}`,
  authResetPassword: `${ALIAS}${AUTH}${RESET_PASSWORD}`,
  authCallback: `${ALIAS}${AUTH}${CALLBACK}`,

  course: `${ALIAS}${MY_COURSES}${COURSE}`,
  stage: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}`,
  catalog: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}`,
  scorm: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${SCORM}`,
  stageTask: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${TASK}`,
  stageTaskAnswers: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${TASK}${TASK_ANSWERS}`,
  stageTaskFinished: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${TASK}${TASK_FINISHED}`,
  stageTaskReviewerComment: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${TASK}${TASK_FINISHED}${TASK_REVIEW}`,
  poll: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${POLL}`,
  pollFinishScreen: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_FINISH}`,
  pollResultScreen: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_RESULT}`,
  pollScreen: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_SCREEN}`,
  courseBooking: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${BOOKING}`,
  courseBookingRegistrations: `${ALIAS}${MY_COURSES}${COURSE}${STAGE}${CATALOG}${BOOKING}${BOOKING_REGISTRATIONS}`,

  menuCourse: `${ALIAS}${COURSE}`,
  menuCourseStage: `${ALIAS}${COURSE}${STAGE}`,
  menuCourseCatalog: `${ALIAS}${COURSE}${STAGE}${CATALOG}`,
  menuCourseScorm: `${ALIAS}${COURSE}${STAGE}${SCORM}`,
  menuCourseStageTask: `${ALIAS}${COURSE}${STAGE}${TASK}`,
  menuCourseStageTaskAnswers: `${ALIAS}${COURSE}${STAGE}${TASK}${TASK_ANSWERS}`,
  menuCourseStageTaskFinished: `${ALIAS}${COURSE}${STAGE}${TASK}${TASK_FINISHED}`,
  menuCourseStageTaskReviewerComment: `${ALIAS}${COURSE}${STAGE}${TASK}${TASK_FINISHED}${TASK_REVIEW}`,
  menuCoursePoll: `${ALIAS}${COURSE}${STAGE}${CATALOG}${POLL}`,
  menuCoursePollFinishScreen: `${ALIAS}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_FINISH}`,
  menuCoursePollResultScreen: `${ALIAS}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_RESULT}`,
  menuCoursePollScreen: `${ALIAS}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_SCREEN}`,
  menuCourseBooking: `${ALIAS}${COURSE}${STAGE}${CATALOG}${BOOKING}`,
  menuCourseBookingRegistrations: `${ALIAS}${COURSE}${STAGE}${CATALOG}${BOOKING}${BOOKING_REGISTRATIONS}`,

  myPolls: `${ALIAS}${MY_POLLS}`,
  myPollsActive: `${ALIAS}${MY_POLLS}${MY_POLLS_ACTIVE}`,
  myPollsFinished: `${ALIAS}${MY_POLLS}${MY_POLLS_FINISHED}`,
  myPoll: `${ALIAS}${MY_POLLS}${POLL}`,
  myPollScreen: `${ALIAS}${MY_POLLS}${POLL}${POLL_SCREEN}`,
  myPollFinishScreen: `${ALIAS}${MY_POLLS}${POLL}${POLL_FINISH}`,
  myPollResultScreen: `${ALIAS}${MY_POLLS}${POLL}${POLL_RESULT}`,

  menuPoll: `${ALIAS}${POLL}`,
  menuPollFinishScreen: `${ALIAS}${POLL}${POLL_FINISH}`,
  menuPollResultScreen: `${ALIAS}${POLL}${POLL_RESULT}`,
  menuPollScreen: `${ALIAS}${POLL}${POLL_SCREEN}`,

  menuCatalog: `${ALIAS}${CATALOG}`,
  menuCatalogPoll: `${ALIAS}${CATALOG}${POLL}`,
  menuCatalogPollFinishScreen: `${ALIAS}${CATALOG}${POLL}${POLL_FINISH}`,
  menuCatalogPollResultScreen: `${ALIAS}${CATALOG}${POLL}${POLL_RESULT}`,
  menuCatalogPollScreen: `${ALIAS}${CATALOG}${POLL}${POLL_SCREEN}`,
  menuCatalogBooking: `${ALIAS}${CATALOG}${BOOKING}`,
  menuCatalogBookingRegistrations: `${ALIAS}${CATALOG}${BOOKING}${BOOKING_REGISTRATIONS}`,

  gamification: isTalentRocksApp ? `${ALIAS}${GAMIFICATION}` : '/',
  gamificationRatings: optionPrefix(
    `${ALIAS}${GAMIFICATION}`,
    `${GAMIFICATION_RATINGS}${GAMIFICATION_RATINGS_GROUP}`,
    isTalentRocksApp,
  ),
  gamificationRules: optionPrefix(`${ALIAS}${GAMIFICATION}`, GAMIFICATION_RULES, isTalentRocksApp),
  gamificationPrizes: optionPrefix(`${ALIAS}${GAMIFICATION}`, GAMIFICATION_PRIZES, isTalentRocksApp),
  gamificationPrize: optionPrefix(
    `${ALIAS}${GAMIFICATION}`,
    `${GAMIFICATION_PRIZES}${GAMIFICATION_PRIZE}`,
    isTalentRocksApp,
  ),
  gamificationRatingsDetails: optionPrefix(
    `${ALIAS}${GAMIFICATION}`,
    `${GAMIFICATION_RATINGS}${GAMIFICATION_RATINGS_GROUP}${GAMIFICATION_RATINGS_DETAILS}`,
    isTalentRocksApp,
  ),
  groupGamification: isTalentRocksApp ? `${ALIAS}${GROUP_GAMIFICATION}` : '/',
  groupGamificationRatings: optionPrefix(`${ALIAS}${GROUP_GAMIFICATION}`, GAMIFICATION_RATINGS, isTalentRocksApp),
  groupGamificationRules: optionPrefix(`${ALIAS}${GROUP_GAMIFICATION}`, GAMIFICATION_RULES, isTalentRocksApp),
  groupGamificationPrizes: optionPrefix(`${ALIAS}${GROUP_GAMIFICATION}`, GAMIFICATION_PRIZES, isTalentRocksApp),
  groupGamificationPrize: optionPrefix(
    `${ALIAS}${GROUP_GAMIFICATION}`,
    `${GAMIFICATION_PRIZES}${GAMIFICATION_PRIZE}`,
    isTalentRocksApp,
  ),
  groupGamificationRatingsDetails: optionPrefix(
    `${ALIAS}${GROUP_GAMIFICATION}`,
    `${GAMIFICATION_RATINGS}${GAMIFICATION_RATINGS_DETAILS}`,
    isTalentRocksApp,
  ),

  myBookings: `${ALIAS}${MY_BOOKINGS}`,
  myBooking: `${ALIAS}${MY_BOOKINGS}${BOOKING}`,
  myBookingRegistrations: `${ALIAS}${MY_BOOKINGS}${BOOKING}${BOOKING_REGISTRATIONS}`,
  myBookingsCurrent: `${ALIAS}${MY_BOOKINGS}${BOOKINGS_CURRENT}`,
  myBookingsAvailable: `${ALIAS}${MY_BOOKINGS}${BOOKINGS_AVAILABLE}`,

  frame: `${ALIAS}${FRAME}`,

  myTasks: `${ALIAS}${MY_TASKS}`,
  myTask: `${ALIAS}${MY_TASKS}${TASK}`,
  myTaskAnswers: `${ALIAS}${MY_TASKS}${TASK}${TASK_ANSWERS}`,
  myTaskFinished: `${ALIAS}${MY_TASKS}${TASK}${TASK_FINISHED}`,
  myTaskReviewerComment: `${ALIAS}${MY_TASKS}${TASK}${TASK_FINISHED}${TASK_REVIEW}`,
  myTasksActive: `${ALIAS}${MY_TASKS}${TASKS_ACTIVE}`,
  myTasksCompleted: `${ALIAS}${MY_TASKS}${TASKS_COMPLETED}`,
  schedule: `${ALIAS}${SCHEDULE}`,

  myLearningTracksAll: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACKS_ALL}`,
  myLearningTracksInProgress: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACKS_IN_PROGRESS}`,
  myLearningTracksCompleted: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACKS_COMPLETED}`,
  myLearningTracksAvailable: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACKS_AVAILABLE}`,
  myLearningTracksStatus: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACKS_STATUS}`,
  myLearningTrack: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}`,

  myLearningTrackCourse: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}`,
  myLearningTrackStage: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}`,
  myLearningTrackCatalog: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}`,
  myLearningTrackScorm: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${SCORM}`,
  myLearningTrackTask: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${TASK}`,
  myLearningTrackTaskAnswers: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${TASK}${TASK_ANSWERS}`,
  myLearningTrackTaskFinished: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${TASK}${TASK_FINISHED}`,
  myLearningTrackTaskReviewerComment: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${TASK}${TASK_FINISHED}${TASK_REVIEW}`,
  myLearningTrackPoll: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${POLL}`,
  myLearningTrackPollFinishScreen: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_FINISH}`,
  myLearningTrackPollResultScreen: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_RESULT}`,
  myLearningTrackPollScreen: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${POLL}${POLL_SCREEN}`,
  myLearningTrackCourseBooking: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${BOOKING}`,
  myLearningTrackCourseBookingRegistrations: `${ALIAS}${MY_LEARNING_TRACKS}${MY_LEARNING_TRACK}${MY_LEARNING_TRACK_STEP}${COURSE}${STAGE}${CATALOG}${BOOKING}${BOOKING_REGISTRATIONS}`,

  dashboard: `${ALIAS}${DASHBOARD}`,
} as const;
