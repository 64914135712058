import styled from 'styled-components';
import { Button as CompButton, Card as CardBase, Title, ProgressStage } from 'components';
import { Grid, MIXINS } from '_constants';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ButtonStyled as InfoButtonStyled } from 'components/LearningTracks/InfoButton/InfoButton.styles';
import { ImageOverlay } from 'components/Image/Image.styles';
import { Percent, Header as ProgressHeader } from 'components/ProgressStage/ProgressStage.styles';
import { ButtonStyled } from 'components/NavigationButton/InlineButton/InlineButton.styles';

export const NavigationButtonOverlay = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 11;
  &&& ${ButtonStyled} {
    @media (max-width: ${Grid.XXXL}px) {
      padding: 0 10px;
      width: 100%;
      justify-content: flex-start;
      border: none;
      border-radius: 0;
      background-color: #f5f5f7;
      text-align: left;
    }
  }
  @media (max-width: ${Grid.XXXL}px) {
    top: 0;
    left: 0;
    width: 100%;
    border: none;
  }
`;

export const InfoButtonWrapper = styled.div`
  min-width: 270px;
  max-width: 856px;
  width: 100%;
  position: absolute;
  @media (max-width: ${Grid.XXXL}px) {
    min-width: auto;
    position: static;
    max-width: initial;
    width: auto;
  }

  @media (max-width: ${Grid.LG}px) {
    margin-left: 0;
    min-width: auto;
  }
  &&& ${InfoButtonStyled} {
    top: 0;
    right: -24px;
    transform: translateX(100%);

    @media (max-width: ${Grid.XXXL}px) {
      border: none;
      top: 0;
      border-radius: 0;
      background-color: #f5f5f7;
      transform: none;
      right: 0;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const Card = styled(CardBase)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  margin-top: 0;

  @media (max-width: ${Grid.XXXL}px) {
    margin-top: 24px;
  }
`;

export const CourseImageOverlay = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  ${MIXINS.ratio16x9}

  & ${ImageOverlay} {
    height: auto;
  }

  & .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  @media (hover: hover) {
    cursor: zoom-in;
  }

  & .ant-image-error {
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }

    & .ant-image-img {
      object-fit: cover;
    }
  }

  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

export const StyledTitle = styled(Title)`
  &&&& {
    font-family: 'Roboto', sans-serif;
    line-height: 32px;
    align-self: flex-start;
    word-break: break-word;
  }
`;

export const ModalButton = styled(CompButton)`
  &&&& {
    display: flex;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    max-width: 100%;
    width: auto;
    height: 40px;
    padding: 0 16px;
    margin-bottom: 24px;
  }

  svg {
    flex-shrink: 0;
    margin-left: 8px;

    path {
      fill: ${({ theme: { palette } }) => palette.primary.main};
    }
  }
`;

export const ProgressStageOverlay = styled.div`
  width: 100%;
  margin-top: 40px;
  @media (max-width: ${Grid.MD}px) {
    margin-left: -10px;
  }
`;

export const StyledProgressStage = styled(ProgressStage)`
  &&& {
    min-height: 100px;
    padding: 16px 24px 20px;
    @media (max-width: ${Grid.MD}px) {
      padding: 17px 14px;
      margin: 0;
    }
    ${Percent} {
      font-family: 'Roboto', sans-serif;
      line-height: 28px;
    }

    ${ProgressHeader} {
      align-items: center;
    }
  }
`;

export const SubmitWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const TextOverlay = styled.div`
  align-self: flex-start;
  margin-top: 16px;
`;

export const LabelsOverlay = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 4px;
  margin-bottom: 16px;
`;
