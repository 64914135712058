import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';
import { LearningTracksStatuses, LearningTrackFormat } from './constants';
import MProgress from './MProgress';
import MFinalScreen from './MFinalScreen';
import MStep from './MStep';

const MLearningTrack = types.model({
  id: types.string,
  name: types.string,
  coverUrl: types.string,
  coverThumbnailUrl: types.string,
  openDate: types.maybeNull(types.string),
  status: types.enumeration<LearningTracksStatuses>('Status', Object.values(LearningTracksStatuses)),
  viewInAdminMode: types.boolean,
  description: types.string,
  isFreeOrderAllowed: types.boolean,
  progress: MProgress,
  finalScreen: MFinalScreen,
  steps: types.array(MStep),
  style: types.maybe(types.enumeration<LearningTrackFormat>('Format', Object.values(LearningTrackFormat))),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  completionDate: types.maybeNull(types.string),
  deadlineDate: types.maybeNull(types.string),
});

export default MLearningTrack;
